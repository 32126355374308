import { storage } from './firebase/firebase';

const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};

const fixedFetch = (path, config) =>
  new Promise((resolve, reject) => {
    fetch(path, config)
      .then(response => response.json())
      .then(json => resolve(json))
      .catch(err => reject(err));
  });

export const uploadToStorage = async blob => {
  var metadata = {
    contentType: 'audio/mp3',
  };
  const name = uuidv4();
  const uploadTask = storage.ref(`/audio-v2/${name}.mp3`).put(blob, metadata);
  return new Promise((resolve) => {
    uploadTask.on('state_changed', log, console.error, () => {
      storage
        .ref('audio-v2')
        .child(`${name}.mp3`)
        .getDownloadURL()
        .then((url) => {
          resolve(url);
        });
    });
  });
};

export const postToSlack = post => {
  const text = `
  ${post.displayName}
  ${post.odai}
  ${post.positions.find(p => p.selected).text}
  ${post.fileURL}
`;
const body = {text};
  const url = 'https://notification-server-acaperap.herokuapp.com/notice';
  // const url = 'http://localhost:4000/notice';
  return fixedFetch(url, {
    cache: 'no-cache',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
    method: 'POST',
  });
};

export const log = (...data) => {
  if (process.env.NODE_ENV === 'production') return;
  console.log(data);
}

import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';

import TagManager from 'react-gtm-module';


import { UserContext } from '../providers/UserProvider';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#deb887",
    borderBottom: '1px solid black'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  headerTitle: {
    textDecoration: 'none',
    color: 'red',
    fontSize: '28px',
    width: '120px'
  },
}));

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to="/">
              <img  className={classes.headerTitle} src="/acaperap-title-logo.svg" alt="" />
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;

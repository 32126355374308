import React from "react";
import Box from "@material-ui/core/Box";
import Footer from "./FooterWeb";
import Header from "./HeaderWeb";

// eslint-disable-next-line react/prop-types
const Layout: React.FC<{ style: React.CSSProperties }> = ({ children, style }) => (
  <>
    <Header />
    <Box
      style={{ backgroundColor: "#deb887", minHeight: "calc(100vh - 50px)", ...style }}
    >
      <Box maxWidth="1080px">
      {children}
      <Footer />
      </Box>
    </Box>
  </>
);

export default Layout;

import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./App.css";

// import Top from "./pages/Top";
// import StartBattle from "./pages/StartBattle";
// import Battle from "./pages/Battle";
// import Help from "./pages/Help";
// import SignIn from "./pages/SignIn";
// import SignUp from "./pages/SignUp";
// import Mypage from "./pages/Mypage";
// import Post from "./pages/Post";
// import PasswordReset from "./pages/PasswordReset";
import Contact from "./pages/web/Contact";
import Home from "./pages/web/Home";
import Terms from "./pages/web/TermsOfUse";
import PrivacyPolicy from "./pages/web/PrivacyPolicy";

// import Header from "./components/Header";
// import Footer from "./components/Footer";

function App() {
  // const userAgent = navigator.userAgent.toLowerCase();
  // const isIOS = userAgent.search(/iphone|ipad|ipod/) > -1;

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/terms-of-use" component={Terms} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Redirect to="/" />
        </Switch>

        {/* <Header />
        <div style={{ minHeight: `calc(${window.innerHeight}px - 86px)` }}>
          <Switch>
            <Route exact path="/" component={Top} />
            <Route exact path="/battle" component={Battle} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/startbattle" component={StartBattle} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/mypage" component={Mypage} />
            <Route exact path="/post" component={Post} />
            <Route exact path="/passwordreset" component={PasswordReset} />
            <Redirect to="/" />
          </Switch>
        </div>
        <Footer /> */}
      </Router>
    </div>
  );
}

export default App;

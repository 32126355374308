import React from 'react';

// import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '30px',
    // backgroundColor: '#deb887'
  }
}));

function FooterWeb() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
          <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a color="inherit" href="https://twitter.com/acaperap">
        Acaperap
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    </div>
  );
}

export default FooterWeb;

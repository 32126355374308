import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import { log } from '../common';
import { IUser } from '../models'

const firebaseConfig = {
  apiKey: "AIzaSyCMC70jqJRckh-wAUJnxjjW21q1Qi2b6dM",
  authDomain: "acaperap-f2810.firebaseapp.com",
  projectId: "acaperap-f2810",
  storageBucket: "acaperap-f2810.appspot.com",
  messagingSenderId: "1061121911636",
  appId: "1:1061121911636:web:bd8e60f3fe948150cb76c1",
  measurementId: "G-BKL7R5FPL0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//analytics is optional for this tutoral
// firebase.analytics();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export const generateUserDocument = async (user: firebase.User, additionalData?: any): Promise<IUser | null | undefined> => {
  log(user);
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        created: new Date(),
        group: '2.0',
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};
const getUserDocument = async (uid: string): Promise<IUser | null | undefined> => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    } as IUser;
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export default firebase;

import React from "react";
import Box from "@material-ui/core/Box";

function Terms() {
  return (
    <Box>
      {/* <h2></h2> */}
      <Box
        m="20px 20px 40px"
        style={{
          fontSize: "14px",
          lineHeight: "22px",
          letterSpacing: "1px",
          textAlign: "left",
        }}
      >
        【利用規約】
        <br />
        <br />
        第一条（利用規約）
        <br />
        本利用規約は「Acaperap」運営事務局（以下、当事務局と言う）が企画・開発・運営する当アプリを利用することについてのサービス利用規約（以下、本規約と言う）を定めることを目的とします。
        <br />
        <br />
        第二条（利用規約の変更）
        <br />
        当事務局は、自らが必要と判断した場合、利用者から事前の承諾を得ることなく、いつでも任意に変更・追加できることとし、当該告知を指定のウェブサイト上に掲示するものとし、当該掲示がなされた時点で効力を生じるものとします。本規約変更後に利用者がサービスを利用した場合、変更後の本規約に同意したものとみなします。変更後の本規約に同意できない利用者につきましては、直ちに本サービスの利用を停止します。
        <br />
        <br />
        第三条（著作権等の権利の帰属）
        <br />
        当事務局は、利用者が本サービスにおいて投稿した全ての文章について、これらを保存します。また、これらをサービスの円滑な運営のため、無償、無期限かつ無条件で、あらゆる態様で利用でき、利用者はこれに同意するものとします。
        <br />
        <br />
        第四条（自己責任）
        <br />
        利用者は本サービスの利用により当事務局又は他者に対し損害を与えた場合、自己の責任と費用をもって一切の損害を賠償するものとします。
        <br />
        <br />
        第五条（禁止行為）
        <br />
        １.
        当事務局は利用者の本サービスの利用に関し、以下の行為を禁止し、仮にこの行為を行った場合、当該利用者の本サービスの利用を直ちにに停止できるものとします。
        <br />
        (1)当事務局又は第三者を不当に差別し又は誹謗中傷する行為。本サービスの名誉・信用を毀損する行為。
        <br />
        (2)第三者への嫌がらせ、わいせつ、嫌悪感を与える表現や内容を含む投稿・書き込み。
        <br />
        (3)公序良俗・法令・本規約に違反する行為。
        <br />
        (4)第三者になりすました登録・投稿・書き込み。
        <br />
        (5)他の利用者へ付きまとい等、他の利用者への迷惑行為。
        <br />
        (6)本サービスの運営を妨害する、あるいはその恐れのある行為。
        <br />
        <br />
        第六条（免責事項及び損害賠償）
        <br />
        １.
        当事務局は本サービスの利用に関連して発生した利用者又は第三者の損害について当事務局に故意・重大な過失がある場合を除き、一切の責任を負わないものとします。
        <br />
        ２.
        当事務局は本サービスを通じたいかなる情報の正確性も保証しなないこととし、利用者同士での紛争には介入致しません。
        <br />
        ３.
        当事務局は本サービスの安定的な運用につとめますが、不可抗力によって本サービスのデータが損失された場合の利用者又は第三者に対する損害の一切の責任を負いません。
        <br />
        <br />
        第七条（利用停止）
        <br />
        当事務局は利用者が次の各号のいずれかに該当する場合、本サービスの一部又は全ての提供を停止、あるいは当事務局が適当と考える措置を取ることができるものとします。又、当事務局は当該措置についての理由を開示する義務を負わないものとし、異議の申し立てをすることはできないものとします。
        <br />
        (1)本規約における禁止事項を行うなどの違反、それに準ずる行為があると当事務局が判断した場合
        <br />
        (2)本サービス内外を問わず利用者の行為が本サービスの運営を妨げる、その意図があると当事務局が判断した場合
        <br />
        <br />
        2022年7月3日制定
      </Box>
    </Box>
  );
}

export default Terms;

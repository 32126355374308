import React, { Component, createContext } from 'react';
import { auth, firestore, generateUserDocument } from '../firebase/firebase';
import { log } from '../common';
import { IUser } from '../models';

export const UserContext = createContext<IUser>(null);

type Props = {
  children: React.ReactNode
};

type State = {
  user: IUser
};

class UserProvider extends Component<Props, State> {
  state: State = {
    user: null,
  };
  unsub: null | (() => void) = null;

  componentDidMount = async () => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (!userAuth) return;
      const user = await generateUserDocument(userAuth);
      this.setState({ user });

      if (!this.unsub && user) {
        const userRef = firestore.doc(`users/${user.uid}`);
        this.unsub = userRef.onSnapshot(doc => {
          log('snapshot', doc.data());
          const user = { uid: userAuth.uid, ...doc.data() } as IUser;
          this.setState({ user });
        });
      }
    });
  };

  componentWillUnmount() {
    if (this.unsub) {
      this.unsub();
    }
  }

  render() {
    // TODO: 動作するか確認
    return <UserContext.Provider value={this.state.user}>{this.props.children}</UserContext.Provider>;
  }
}

export default UserProvider;

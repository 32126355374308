import React from "react";
import Box from "@material-ui/core/Box";

function Help() {
  return (
    <Box>
      {/* <h2>プライバシーポリシー</h2> */}
      <Box
        m="20px 20px 40px"
        style={{
          fontSize: "14px",
          lineHeight: "22px",
          letterSpacing: "1px",
          textAlign: "left",
        }}
      >
        プライバシーポリシー
        <br />
        <br />1 個人情報の利用目的
        <br />
        <br />
        当方は､取得した個人情報を以下の利用目的のために利用いたします｡
        <br />
        <br />
        　A：当方サービスの提供のため｡
        <br />
        　B：お客様へのサポート･連絡､ならびに商品･サービスの案内のため｡
        <br />
        　C：利用状況の分析､サービス向上のための資料などとして､個人を識別できない形式に加工して利用するため｡
        <br />
        　D：広告利用のため。
        <br />
        <br />
        <br />2 利用目的による制限
        <br />
        <br />
        当方は､以下の何れかに該当する場合を除き､取得した個人情報を利用目的の達成に必要な範囲内で取り扱います｡
        <br />
        <br />
        　A：お客様ご本人様の同意がある場合
        <br />
        　B：法令に基づき開示･提供を求められた場合
        <br />
        　C：人の生命､身体または財産の保護のために必要な場合であって､ご本人様の同意を得ることが困難である場合
        <br />
        　D：国または地方公共団体等が公的な事務を実施するうえで､協力する必要がある場合であって､ご本人様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
        <br />
        <br />
        <br />3 第三者への委託
        <br />
        <br />
        当方は､個人情報の取り扱いを第三者に委託する場合には､委託を受けた者に対して適切な管理･監督を行います｡
        <br />
        <br />
        <br />4 個人情報の第三者への開示
        <br />
        <br />
        当方は､個人情報保護法等の法令に定めのある場合を除き､個人情報をあらかじめご本人の同意を得ることなく､第三者に提供致しません｡ただし､以下の場合はこの限りではありません｡
        <br />
        <br />
        　A:統計的なデータなど個人を識別できない状態で開示･提供する場合
        <br />
        　B:第2項に定める利用目的で第三者に業務委託する場合
        <br />
        <br />
        <br />5 個人情報の管理
        <br />
        <br />
        当方は､お客様の個人情報を適切かつ安全に管理いたします｡
      </Box>
    </Box>
  );
}

export default Help;

import React from "react";
import Box from "@material-ui/core/Box";
import Layout from "../../components/Layout";

function Home() {
  return (
    <Layout style={window.innerWidth < 800 ? { background: "url('/assets/app-hero.jpg') 0 0 no-repeat", backgroundSize: 'cover' } :
      { backgroundImage: "url('/assets/app-hero-pc.jpg')", backgroundPosition:  "right 100px top", backgroundRepeat: "no-repeat", backgroundSize: 'contain', padding: '0 100px' }}>
      <Box p="20px" style={{ textAlign: "center" }}>
        <Box textAlign="left" p={window.innerWidth < 800 ? '0' : '0 30px'}>
          <h1 style={{ margin: '0', color: '#ffffff', WebkitTextStroke: '1px  #5a1e1e' }}>
            フリースタイル
            <br />
            ラップ
            <br />
            の野良部屋
          </h1>
        </Box>
        <Box p="30px" textAlign={window.innerWidth < 800 ? 'center' : 'left'}>
          <Box mt="30px">
            <a
              href="https://twitter.com/acaperap?ref_src=twsrc%5Etfw"
              className="twitter-follow-button"
              data-show-count="false"
            >
              Follow @acaperap
            </a>
            <script
              async
              src="https://platform.twitter.com/widgets.js"
            ></script>
          </Box>
          <Box mt="30px">
            <div>
              <a id="appstore-button" href="https://apps.apple.com/jp/app/%E3%82%A2%E3%82%AB%E3%83%9A%E3%83%A9%E3%83%83%E3%83%97/id1632356739" target="_blank" rel="noreferrer">
                <img src="/assets/download-appstore-black.svg" />
              </a>
            </div>
          </Box>
          <Box mt="30px">
            <a href="/contact">お問い合わせ</a>
          </Box>
          <Box mt="30px">
            <a href="/terms-of-use">利用規約</a>
          </Box>
          <Box mt="30px">
            <a href="/privacypolicy">プライバシーポリシー</a>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

export default Home;
